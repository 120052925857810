import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Lottie } from '@crello/react-lottie';
import data from './data.json';
import Button from '@components/Button';
import styles from './HeroAnimation.module.scss';
import useIsMounted from '@hooks/useIsMounted';
import useEvent from '@hooks/useEvent';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

export default function HeroAnimation({ modalIsOpen }) {
  const [playingState, setPlayingState] = useState('stopped');
  const [isComplete, setIsComplete] = useState(false);
  const isMounted = useIsMounted();
  const hasStarted = useRef(false);
  const animation = useRef(null);
  const [ref, inView] = useInView({
    threshold: 0
  });

  const setAnimationState = useCallback(isPaused => {
    if (!hasStarted.current) {
      return;
    }

    if (isPaused) {
      setPlayingState('paused');
    } else if (!isComplete) {
      setPlayingState('playing');
    }
  }, [isComplete]);

  useEffect(() => {
    setAnimationState(!inView);
  }, [inView, setAnimationState]);

  useEvent('visibilitychange', () => {
    setAnimationState(document.visibilityState !== 'visible');
  });

  return (
    <div
      className={styles.animation}
      ref={ref}
    >
      <Lottie
        config={{
          autoplay: false,
          renderer: 'svg',
          animationData: data,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          }
        }}
        ref={animation}
        playingState={modalIsOpen ? 'paused' : playingState}
        lottieEventListeners={[
          {
            name: 'complete',
            callback: () => setIsComplete(true)
          },
          {
            name: 'DOMLoaded',
            callback: () => {
              setTimeout(() => {
                if (isMounted.current) {
                  hasStarted.current = true;
                  setPlayingState('playing');
                }
              }, 300);
            }
          }
        ]}
      />
      <div
        className={classNames(styles.replay, {
          [styles.visible]: isComplete
        })}
      >
        <Button
          onClick={() => {
            setIsComplete(false);
            setPlayingState('playing');
            animation.current?.animationItem.goToAndPlay(0);
          }}
        >
          Replay
        </Button>
      </div>
    </div>
  );
}