import React from 'react';
import { Content } from '@layout';
import HeroAnimation from './HeroAnimation';
import styles from './Hero.module.scss';

export default function Hero({ modalIsOpen }) {
  return (
    <Content
      type="section"
      className={styles.hero}
    >
      <div className={styles.text}>
        <h1>Real time risk management</h1>
        <p>Help your team be successful with actionable insights and automatic issue detection.</p>
      </div>

      <HeroAnimation modalIsOpen={modalIsOpen} />
    </Content>
  );
}